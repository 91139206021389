import baseService from '../services/baseService'
const initialState = {
    isLoggedIn: sessionStorage.getItem("Id")?true:false,
    Id: sessionStorage.getItem("Id") || 0,
    authToken: sessionStorage.getItem("authToken") || '',
    name: sessionStorage.getItem("name") || '',
    
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case 'USER_LOGOUT':
        sessionStorage.clear();
        return {
          ...state,
          isLoggedIn:false
        }
      case 'USER_LOGIN':
          sessionStorage.setItem("Id",action.payload.user_id);
          sessionStorage.setItem("authToken",action.payload.authToken);
          sessionStorage.setItem("name",action.payload.first_name);
          baseService.addAuthToken();
        return {
          ...state,
          isLoggedIn: true,
          Id:action.payload.Id,
          authToken:action.payload.authToken,
          name:action.payload.first_name
        }
      

      default:
        return state
    }
  }