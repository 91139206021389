import React from 'react'
import {connect} from 'react-redux'




class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          
        }
    }
    componentDidMount()
    {
      console.log(this.props);
      this.props.logOut();
      this.props.history.push("/");
    }  

  render() {
    return (
      <div className="d-flex justify-content-center align-items-center body100">
        <div style={{width:350}}>
          <h3>Log Out</h3>
          <div>Please wait...</div>  
        </div>
      </div>  
    );
  }
}



const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      logOut: () => {
          dispatch({ type: "USER_LOGOUT"});
      }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)
