import React, {Component} from "react";
import { connect } from 'react-redux'
import {Link} from 'react-router-dom'
class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageloading:false
    }
    
  }
  
  
    componentDidMount() {
      
      
    }
    
	componentWillReceiveProps(nextProps) {
    
	}
   
  render() {
    return (
    <div className="menu" style={{backgroundColor:'#6d6f71'}}>
        
        <div className="pl-2 d-flex" style={{backgroundColor:'#1e81b1'}}>
          <div style={{backgroundColor:'white'}}><img src="logo.webp" alt="" /></div>
        </div>
        <div className="d-flex" style={{flex:1}}>
          <div style={{flex:1}}></div>
          <div className="mr-3">
            <Link to="/logout" style={{color:'white',fontSize:18}}>Logout</Link>
          </div>
        </div>
     </div>
    )

  }
}


const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
    changeCompany: () => {
        dispatch({ type: "COMPANY_SELECT", payload:{CompanyId:0,CompanyName:""} });
    },
    selectCompany: (data) => {
      dispatch({ type: "COMPANY_SELECT", payload:data });
    }
};
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)