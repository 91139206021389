import React from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import * as API from '../../../services/projectuser'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

class Index extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            records:[],
            search:'',
            IsLegalNameSame:true
        }
    }
    componentDidMount()
    {
        if(this.props.id)
        {
            API.select(this.props.id).then((res)=>{
               this.form.setFieldsValue(res.data);
            })
        }
    }
    render() {
        
        return (
            <div>
                <Form ref={(r)=>this.form=r} layout="vertical" 
                onFinish={(values)=>{ 
                    this.setState({saving:true})
                    if(this.props.id)
                        values.user_id=this.props.id;
                    API.save(values).then((res)=>{
                        if(values.password==="")
                            values.password=undefined;
                        this.props.onSave(values);
                    })
                 }}>
                    <h5>Project Details</h5>
                    <Row gutter={15}>
                        <Col md={12}>
                            <Form.Item name="first_name" label="First Name" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength="255" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="last_name" label="Last Name" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength="255" />
                            </Form.Item>
                        </Col>       
                        <Col md={12}>
                            <Form.Item name="phone" label="Phone" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength={20} />
                            </Form.Item>
                        </Col>       
                        <Col md={12}>
                            <Form.Item name="email" label="Email" rules={[{required:true,message:"Please enter"},{type:'email',message:'Please enter valid email.'}]}>
                                <Input maxLength="255" />
                            </Form.Item>
                        </Col>       
                        <Col md={12}>
                            <Form.Item name="password" label="Password" rules={[{required:!this.props.id,message:"Please enter"}]}>
                                <Input maxLength="255" />
                            </Form.Item>
                        </Col>       
                    </Row>
                   
                   
                    
                    
                    <div className="mb-3">
                    <Button htmlType="submit" type="primary" loading={this.state.saving}>SAVE</Button>
                    <Button onClick={()=>{ this.props.onCancel() }} className="ml-2">CANCEL</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

  
  export default Index
  
  