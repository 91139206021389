import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Menu from './components/Menu'
import SideMenu from './components/SideMenu'
import Home from '../Home'
import Project from '../Project'
import ProjectDetail from '../Project/detail'
import Crons from '../Project/crons'
import User from '../User'
import Login from '../Login'
import Logout from '../Logout'
import { connect } from 'react-redux'
import Layout from 'antd/lib/layout'
import $ from 'jquery'
import './CSS/table.css'
import './CSS/form.css'
const { Header,  Sider, Content } = Layout;


class App extends React.Component {

  constructor(props) {
      super(props);
      this.state = {
       
      }
    }

componentDidMount()
{
  $('body').on('keydown', 'input, select', function(e) {
    if (e.key === "Enter") {
        var self = $(this), form = self.parents('form:eq(0)'), focusable, next;
        focusable = form.find('input,a,select,button,textarea').filter(':visible');
        next = focusable.eq(focusable.index(this)+1);
        if (next.length) {
            next.focus();
        } else {
           // form.submit(); 
        }
        return false;
    }
  });
}
render() {
  return (
  <Layout>
    {this.props.auth.isLoggedIn===true &&  <Header style={{paddingLeft:0,paddingRight:0}}>
      <Menu />
    </Header>}
    
    {this.props.auth.isLoggedIn===true?
    <Layout>
      <Sider width="180" theme="light">
        <SideMenu />
      </Sider>
      <Content hasSider={true}>
      <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/projects" component={Project} />
      <Route exact path="/projects/:id" component={ProjectDetail} />
      <Route exact path="/users" component={User} />
      <Route exact path="/crons" component={Crons} />
      <Route exact path="/logout" component={Logout} />

      <Route component={Home} />
      </Switch>
      </Content>
    </Layout>:<Content>
    
    <Switch>  <Route exact path="/" component={Login} />
      <Route exact path="/login" component={Login} />
      
      <Route component={Login} />
    </Switch>
    </Content>}
    
  </Layout>
)

  }
}

const mapStateToProps = ({ auth }) => ({
  auth: auth
})



export default connect(
  mapStateToProps,
  null
)(App)
