import React from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Button  from 'antd/lib/button';
import * as auth from '../../../services/projectuser'

class Index extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {

        }
    }
    componentDidMount()
    {

    }
    
    Login(values)
    {
      this.setState({saving:true});
      auth.changepassword(values).then((response)=>{
        if(response.data.status===1)
        {
            this.props.onClose();
            Modal.success({title:'Password changed successfully'});
        }
        else
        {
            Modal.error({title:'Old password not matching'});
        }
        this.setState({saving:false});
        
      }).catch((err)=>{
        this.setState({saving:false})
        Modal.error({title:"Something went wrong.."});
      })
    }
  
    
  
  render() {
    
    return (
          
          <Form layout="vertical" onFinish={(e)=>this.Login(e)}>
            
            <Form.Item name="password" label="" rules={[{required:true,message:'Please enter'}]}>
              <Input.Password placeholder="Old Password" style={{border:'none',borderBottom:'1px solid #c4c4c4',height:50,marginTop:10}} />
            </Form.Item>
            <Form.Item name="new_password" label="" rules={[{required:true,message:'Please enter'}]}>
              <Input.Password placeholder="New Password" style={{border:'none',borderBottom:'1px solid #c4c4c4',height:50,marginTop:10}} />
            </Form.Item>
            <Form.Item name="confirm_password" label="" hasFeedback rules={[{required:true,message:'Please enter'},
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('new_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('The two passwords that you entered do not match!'));
            },
          })]}>
              <Input.Password placeholder="Confirm Password" style={{border:'none',borderBottom:'1px solid #c4c4c4',height:50,marginTop:10}} />
            </Form.Item>
            <div className="d-flex mt-5">
              <Button htmlType="submit" type="primary" loading={this.state.saving} style={{backgroundColor:'black',borderWidth:0,height:40,borderRadius:6,fontSize:17,paddingLeft:20,paddingRight:20}}>Change Password</Button>
            </div>
      
          </Form>
      
    );
  }
}



export default Index

