import React from 'react'
import  Menu  from 'antd/lib/menu';
import {Link} from 'react-router-dom'
import ChangePassword from './ChangePassword'
import  Modal  from 'antd/lib/modal';

class SideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
     
    }
    
  }
  render() {
    return (
      [<Menu
        style={{ width: 180 }}
        defaultSelectedKeys={['1']}
        defaultOpenKeys={['sub1']}
        mode="inline"
      >
        
            <Menu.Item key="1"><Link to="/"></Link>Home</Menu.Item>
            <Menu.Item key="2"><Link to="/projects"></Link>Projects</Menu.Item>
            <Menu.Item key="3"><Link to="/users"></Link>Users</Menu.Item>
            <Menu.Item key="4"><Link to="/crons"></Link>Crons</Menu.Item>
            <Menu.Item key="5" onClick={()=>{  this.setState({isChangePassword:true}) }}>Change Password</Menu.Item>
        
      </Menu>,this.state.isChangePassword?<Modal footer={null} visible={true} title="Change Password" onCancel={()=>{ this.setState({isChangePassword:false}) }}>
          <ChangePassword onClose={()=>{ this.setState({isChangePassword:false}) }} />
      </Modal>:'']
    );
  }
}

export default SideMenu;