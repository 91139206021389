import baseService from './baseService';
export function all(){
    return baseService.get('/projectuser/all');
}

export function select(id){
    return baseService.get(`/projectuser/select?id=${id}`);
}
export function save(data){
    if(data.user_id)
        return baseService.post('/projectuser/update',data);
    else
        return baseService.post('/projectuser/insert',data);
}
export function changepassword(data){
    return baseService.post('/projectuser/changepassword',data);
}
export function remove(id){
    return baseService.post(`/projectuser/delete`,{user_id:id});
}