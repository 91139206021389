import React from 'react'
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'


class Index extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {

        }
    }
    componentDidMount()
    {
      this.LoadData();
    }
    
    LoadData()
    {
      axios.get(`https://api.weather.gov/points/${this.props.latitude},${this.props.longitude}`).then((res)=>{
        this.setState({data:res.data})
        axios.get(`https://api.weather.gov/gridpoints/${res.data.properties.gridId}/${res.data.properties.gridX},${res.data.properties.gridY}`).then((response)=>{
          this.setState({detail:response.data.properties})
        })
      })
    }
  
    
  
  render() {
    let data=this.state.data;
    let detail=this.state.detail;
    if(detail)
    console.log(detail.apparentTemperature);

    let dates = [moment(),moment().add(1,'day'),moment().add(2,'day'),moment().add(2,'day'),moment().add(4,'day'),moment().add(5,'day'),moment().add(6,'day')];

    let times = ["04:00:00","10:00:00","16:00:00","22:00:00"];

    if(!data)
      return <div />;
    return (
      <div>
        <div style={{textAlign:'center'}}>{data.properties.relativeLocation.properties.city}, {data.properties.relativeLocation.properties.state}</div>
        {this.state.detail && <div>

          <table className="table table-bordered mytable" style={{fontSize:10}}>
            <tr>
              <th width="100">Date</th>
              {dates.map((d)=><th colSpan="4" className="text-center">{d.format("ddd MMM DD")}</th>)}
              
            </tr>
            <tr>
              <td>Time</td>
              {dates.map((d)=>{
                return times.map((dd)=><td className="text-center">
                  {moment(moment(d).format("YYYY-MM-DD") +" "+dd).format("ha")}
                </td>)
              })}
            </tr>
            <tr>
              <td>Temp(F)</td>
             
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.temperature.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);

                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {(pp[0].value*9/5)+32}
                  </td>
                
                })
              })}
            </tr>
            <tr>
              <td>Dew Point(F)</td>
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.dewpoint.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);
                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {Math.round(parseFloat(pp[0].value)*9.0/5.0)+32}
                  </td>
                
                })
              })}
            </tr>
            <tr>
              <td>Relative Humidity</td>
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.relativeHumidity.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);
                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {pp[0].value}
                  </td>
                
                })
              })}
            </tr>
            <tr>
              <td>Wind Direction</td>
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.windDirection.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);
                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {pp[0].value}
                  </td>
                
                })
              })}
            </tr>
            <tr>
              <td>Wind Speed(mph)</td>
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.windSpeed.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);
                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {(pp[0].value* 0.621371).toFixed()}
                  </td>
                
                })
              })}
            </tr>
            <tr>
              <td>Wind Gust(mph)</td>
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.windGust.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);
                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {(pp[0].value* 0.621371).toFixed()}
                  </td>
                
                })
              })}
            </tr>
            <tr>
              <td>Cloud Cover(%)</td>
              {dates.map((d)=>{
                return times.map((dd)=>
                {
                  
                  let pp=_.filter(detail.skyCover.values,(a)=>moment(a.validTime.split("/")[0].substring(0,16).replace("T"," ")).diff(moment(moment(d).format("YYYY-MM-DD") +" "+dd.substring(0,5)).add(7,"hours"))>0);
                  if(pp.length===0)
                    return <td className="text-center"></td>;

                  return <td className="text-center">
                    {pp[0].value}
                  </td>
                
                })
              })}
            </tr>

            

            

            
          </table>
        </div>}
     </div>
    );
  }
}




export default Index;
