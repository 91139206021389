import React from 'react'
import PageHeader from 'antd/lib/page-header'



class Index extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
       
        }
    }
    componentDidMount()
    {
       
    }
    
    render() {
    
        return (
            <div>
                <PageHeader title="Cron"  />
                <div className="m-3">
                    <a href="/api/welcome/predicting_alert_cron" target="_blank">50% change cron</a><br /><br />
                    <a href="/api/welcome/station_alert_cron" target="_blank">Rainfall Alert Cron</a><br /><br />
                    <a href="/api/welcome/forecast_cron" target="_blank">Forecast Cron</a><br /><br />
                    <a href="/api/welcome/observations_cron" target="_blank">Oservations Cron</a><br /><br />
                    <a href="/api/welcome/forecast_alert_cron" target="_blank">Forecast Alert Cron</a><br />
                </div>
            </div>
        );
    }
}


  export default Index;
  