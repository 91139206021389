import React from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Button from 'antd/lib/button'
import Divider from 'antd/lib/divider';
import Select from 'antd/lib/select';
import * as API from '../../../services/project'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'

class Index extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            records:[],
            search:'',
            IsLegalNameSame:true
        }
    }
    componentDidMount()
    {
        if(this.props.id)
        {
            API.select(this.props.id).then((res)=>{
               this.form.setFieldsValue(res.data);
            })
        }
    }
    render() {
        
        return (
            <div>
                <Form ref={(r)=>this.form=r} layout="vertical" 
                onFinish={(values)=>{ 
                    this.setState({saving:true})
                    if(this.props.id)
                        values.project_id=this.props.id;
                    API.save(values).then((res)=>{
                        
                        this.props.onSave(values);
                    })
                 }}>
                    <h5>Project Details</h5>
                    <Form.Item name="client_name" label="Client Name" rules={[{required:true,message:"Please enter"}]}>
                        <Input maxLength="255" />
                    </Form.Item>
                   
                    <Form.Item name="project_number" label="Project Number" rules={[{required:true,message:"Please enter"}]}>
                        <Input maxLength="100" />
                    </Form.Item>
                    
                    <Divider />
                    <h5>Project Address</h5>
                    <Row gutter={15}>
                        <Col md={12}>
                            <Form.Item name="address" label="Address">
                                <Input maxLength="1024" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="city" label="City">
                                <Input maxLength="100" />
                            </Form.Item>
                        </Col>       
                        <Col md={8}>
                            <Form.Item name="state" label="State">
                                <Input maxLength="100" />
                            </Form.Item>        
                        </Col>    
                        <Col md={8}>
                            <Form.Item name="country" label="Country">
                                <Input maxLength="100" />
                            </Form.Item>
                        </Col>
                        <Col md={8}>
                            <Form.Item name="zipcode" label="Zip Code">
                                <Input maxLength="100" />
                            </Form.Item>
                        </Col>             
                    </Row>
                    <Divider />
                    <h5>Geo Location</h5>
                    <Row gutter={15}>
                        <Col md={12}>
                            <Form.Item name="latitude" label="Latitude" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength="50" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="longitude" label="Longitude" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength="50" />
                            </Form.Item>
                        </Col>       
                    </Row>
                    <Divider />
                    <h5>Weather Stations</h5>
                    <Row gutter={15}>
                        <Col md={12}>
                            <Form.Item name="station_id" label="WU Station ID" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength="100" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="nws_station_id" label="NWS Station ID" rules={[{required:true,message:"Please enter"}]}>
                                <Input maxLength="100" />
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="station_monitoring" label="Station Monitoring" rules={[{required:true,message:"Please enter"}]}>
                                <Select placeholder="Select...">
                                    <Select.Option value={"Active"}>Active</Select.Option>
                                    <Select.Option value={"Closed"}>Closed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col md={12}>
                            <Form.Item name="status" label="Status" rules={[{required:true,message:"Please enter"}]}>
                                <Select placeholder="Select...">
                                    <Select.Option value={"Active"}>Active</Select.Option>
                                    <Select.Option value={"Sleep"}>Sleep</Select.Option>
                                    <Select.Option value={"Closed"}>Closed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>      
                        <Col md={12}>
                            <Form.Item name="forecast_status" label="Forecast Status" rules={[{required:true,message:"Please enter"}]}>
                                <Select placeholder="Select...">
                                    <Select.Option value={"Active"}>Active</Select.Option>
                                    <Select.Option value={"Closed"}>Closed</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>       
                    </Row>


                    
                    <div className="mb-3">
                    <Button htmlType="submit" type="primary" loading={this.state.saving}>SAVE</Button>
                    <Button onClick={()=>{ this.props.onCancel() }} className="ml-2">CANCEL</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

  
  export default Index
  
  