import _ from 'lodash'
const StringUtil = {
    search:function(collection, text, exclude) {
      text = _.toLower(text);
      return _.filter(collection, function (object) {
        return _(object).omit(exclude).some(function (string) {
          return _(string).toLower().includes(text);
        });
      });
    }
}

export default StringUtil;