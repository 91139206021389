import React from 'react'
import * as API from '../../services/project'
import * as UserAPI from '../../services/projectuser'
import PageHeader from 'antd/lib/page-header'
import {Redirect} from 'react-router-dom'
import Select from 'antd/lib/select'
import Space from 'antd/lib/space'
import Button from 'antd/lib/button'
import _ from 'lodash'
import Modal from 'antd/lib/modal'
import Pdf from "react-to-pdf";
import ProjectForm from './components/ProjectForm'
import CurrentForecast from './reports/current_forecast'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'

import Drawer from 'antd/lib/drawer'
import moment from 'moment-timezone'
import axios from 'axios'
const pdfref = React.createRef();
class Index extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            records:[],
            search:'',
            users:[],
            project_users:[]
        }
    }
    componentDidMount()
    {
        this.LoadData();
        this.LoadUsers();
       
    }
    LoadData()
    {
        this.setState({loading:true})
        API.select(this.props.match.params.id).then((response)=>{
            
            this.setState({record:response.data,loading:false,project_users:response.data.users});
            this.LoadWeatherData(response.data);
        })
    }
    LoadUsers()
    {
        this.setState({loading:true})
        UserAPI.all().then((response)=>{
            this.setState({users:response.data,loading:false});
        })
    }
    LoadWeatherData(r)
    {
        axios.get(`https://api.weather.gov/points/${r.latitude},${r.longitude}`).then((res)=>{
            this.setState({data:res.data})
            axios(res.data.properties.forecast).then((res)=>{
                this.setState({forecast:res.data});
                //precipChance:_.max(res.data.daypart[0].precipChance

            })
            axios(res.data.properties.county).then((res)=>{
                this.setState({county:res.data})
            })
            axios(res.data.properties.forecastGridData).then((res)=>{

                //console.log(res.data.properties.probabilityOfPrecipitation.values)
                this.setState({precipChance:_.max(res.data.properties.probabilityOfPrecipitation.values.map((a)=>a.value))});
                

            })

            axios.get(`https://api.weather.com/v3/wx/forecast/daily/7day?apiKey=6532d6454b8aa370768e63d6ba5a832e&geocode=${r.latitude},${r.longitude}&language=en-US&units=e&format=json`).then((res)=>{
            
            this.setState({precipChance2:_.max(res.data.daypart[0].precipChance)})
        })
            
            
        })
      
        
    }
    
    Add()
    {
        API.insert_project_users(this.props.match.params.id,this.state.user_id).then((res)=>{
            this.setState({project_users:res.data,user_id:undefined});
        })
    }

    Remove(d)
    {
        this.setState({deleting:d})
        API.delete_project_users(this.props.match.params.id,d.user_id).then((res)=>{
            this.setState({project_users:res.data,deleting:undefined});
        })
    }
    
    render() {
       
       let record = this.state.record;
       let pusers = [];
       let users = [];
        if(record && this.state.users)
        {
            pusers = _.filter(this.state.users,(a)=>_.filter(this.state.project_users,{user_id:a.user_id}).length>0);
            users = _.filter(this.state.users,(a)=>_.filter(this.state.project_users,{user_id:a.user_id}).length===0);
        }

        return (
            <div>
                
                {this.state.popupurl && <Modal title="Report" visible={true} width="1200px" footer={null} onCancel={()=>{this.setState({popupurl:undefined})}}>
                    <Pdf targetRef={pdfref} filename="div-blue.pdf">
                        {({toPdf}) => (
                            <button onClick={toPdf}>Generate pdf</button>
                        )}
                    </Pdf>
                        <div ref={pdfref} id="mypdfdiv">
                            <CurrentForecast latitude={record.latitude} longitude={record.longitude} />
                        </div>
                    </Modal>}
                {this.state.redirect && <Redirect to={this.state.redirect} />}
                <PageHeader title="Project Detail" onBack={()=>{ this.setState({redirect:"/projects"}) }} extra={<Button icon={<EditOutlined />} type="primary" onClick={()=>{ this.setState({IsEdit:true}) }}>Edit</Button>} />
                {this.state.IsEdit && <Drawer visible="true" width="1000" title="Edit Project" onClose={()=>this.setState({IsEdit:false})}>
                    <ProjectForm id={this.props.match.params.id} onSave={()=>{ this.setState({IsEdit:false}); this.LoadData(); }} onCancel={()=>this.setState({IsEdit:false})} />
                </Drawer>}
                {record && <div className="m-3">
                    <div className="row">
                        <div className="col-md-3">
                            <b>Current Date</b><br />
                            {moment().format("MM/DD/YYYY")}
                        </div>
                        <div className="col-md-3">
                            <b>Current Time</b><br />
                            {moment().format("hh:mm a")}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Client Name</b><br />
                            {record.client_name}
                        </div>
                        <div className="col-md-3">
                            <b>Project Number</b><br />
                            {record.project_number}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Project Address</b><br />
                            {record.address}
                        </div>
                        <div className="col-md-3">
                            <b>City</b><br />
                            {record.city}
                        </div>
                        <div className="col-md-3">
                            <b>State</b><br />
                            {record.state}
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Country</b><br />
                            {record.country}
                        </div>
                        <div className="col-md-3">
                            <b>Zip</b><br />
                            {record.zipcode}
                        </div>
                        
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Latitude</b><br />
                            {record.latitude}
                        </div>
                        <div className="col-md-3">
                            <b>Longitude</b><br />
                            {record.longitude}
                        </div>
                        
                    </div>
                    {this.state.data && <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Daily Weather Monitoring</b><br />
                            {record.station_monitoring}
                        </div>
                        <div className="col-md-3">
                            <b>NWS Forecast Office</b><br />
                            {this.state.data.properties.cwa}
                        </div>
                        <div className="col-md-3">
                            <b>NWS Station ID</b><br />
                            {record.nws_station_id}
                        </div>
                        {this.state.county && <div className="col-md-3">
                            <b>NWS County Code</b><br />
                            {this.state.county.properties.id}
                        </div>}
                        
                    </div>}

                    <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Forecast Status</b><br />
                            {record.forecast_status}
                        </div>
                        <div className="col-md-3">
                            <b>Weather Underground Station Monitoring</b><br />
                            {record.status}
                        </div>
                        <div className="col-md-3">
                            <b>Weather Underground Station Monitoring ID</b><br />
                            {record.station_id}
                        </div>
                        
                    </div>

                    {this.state.forecast && <div className="row mt-3">
                        <div className="col-md-3">
                            <b>Current Temperature</b><br />
                            {this.state.forecast.properties.periods[0].temperature} {this.state.forecast.properties.periods[0].temperatureUnit}
                        </div>
                        <div className="col-md-3">
                            <b>Probability of Precipitation</b><br />
                            {this.state.precipChance}% 
                        </div>
                        <div className="col-md-3">
                            <b>Wind Speed</b><br />
                            {this.state.forecast.properties.periods[0].windSpeed}
                        </div>
                        
                        
                    </div>}
                    {this.state.forecast && <div className="row mt-3">
                        <div className="col-md-12">
                            <b>NWS Weather Alerts Summary</b><br />
                            
                            Last Update: {moment(this.state.forecast.properties.updated).format("LLL")}<br />
                            {this.state.forecast.properties.periods[0].detailedForecast}
                        </div>
                        
                        
                        
                    </div>}

                    <div className="mt-3">
                        <div><b>Project Managers:</b><Space>
                            
                            <Select placeholder={"Select..."} style={{width:250}} value={this.state.user_id} onChange={(e)=>{ this.setState({user_id:e}) }}>
                                {users.map((d)=><Select.Option value={d.user_id}>{d.first_name} {d.last_name}</Select.Option>)}
                            </Select>
                            <Button type="primary" onClick={()=>{ this.Add() }} disabled={!this.state.user_id}>Add</Button>
                        </Space></div>
                        <table className="table mt-2">
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th width="60"></th>
                            </tr>
                            {pusers.map((d)=><tr>
                                <td>{d.first_name} {d.last_name}</td>
                                <td>{d.email}</td>
                                <td>{d.phone}</td>
                                <td>{this.state.deleting===d?<LoadingOutlined spin={true} style={{fontSize:20,color:'red'}} />:<DeleteOutlined style={{fontSize:20,cursor:'pointer',color:'red'}} onClick={()=>this.Remove(d)} />}</td>
                            </tr>)}
                            
                        </table>
                        
                    </div>
                    
                    <div className="d-flex mt-3">
                        <div style={{flex:1,marginRight:10}}>
                                <a href={`https://www.weather.gov/wrh/print?lat=${record.latitude}&lon=${record.longitude}`} target="_blank" rel="noreferrer" className="link_btn">Current Forecast</a>
                        </div>
                        <div style={{flex:1,marginRight:10}}>
                            <a href={`https://www.wrh.noaa.gov/mesowest/getobext.php?wfo=${this.state.data?this.state.data.properties.cwa:''}&sid=${record.nws_station_id}&num=72&raw=0`} target="_blank" rel="noreferrer" className="link_btn">Weather Observations</a>
                        </div>
                        <div style={{flex:1,marginRight:10}}>
                            <a href={`https://www.wunderground.com/weather/${record.country}/${record.state}/${record.city.replace(/ /g,'-')}`} target="_blank" rel="noreferrer" className="link_btn">Weather Station</a>
                        </div>
                        <div style={{flex:1,marginRight:10}}>
                            <a href={`https://www.weather.gov/forecastmaps`} target="_blank" rel="noreferrer" className="link_btn">National Forecast Maps</a>
                        </div>
                        <div style={{flex:1,marginRight:10}}>
                            <a href={`https://www.spc.noaa.gov/`} target="_blank" rel="noreferrer" className="link_btn">Storm Prediction Center</a>
                        </div>
                        <div style={{flex:1,marginRight:10}}>
                            <a href={`http://radar.weather.gov/`} target="_blank" rel="noreferrer" className="link_btn">NWS Radar</a>
                        </div>
                        <div style={{flex:1,marginRight:10}}>
                            <a href={`https://madis-data.ncep.noaa.gov/MadisSurface/`} target="_blank" rel="noreferrer" className="link_btn">MADIS Map</a>
                        </div>
                        <div style={{flex:1}}>
                            <a href={`https://www.1800wxbrief.com/Website/interactiveMap`} target="_blank" rel="noreferrer" className="link_btn">Leidos Weather Map</a>
                        </div>
                        
                        

                        
                        
                        
                    </div>
                    
                    
                </div>}
            </div>
        );
    }
}


  export default Index;
  