import React from 'react'
import * as API from '../../services/projectuser'
import PageHeader from 'antd/lib/page-header'
import Table from 'antd/lib/table'
import Drawer from 'antd/lib/drawer'
import Button from 'antd/lib/button'
import Input from 'antd/lib/input'
import Form from './components/Form'
import StringUtil from '../../utils/stringrutil'
import DeleteOutlined from '@ant-design/icons/DeleteOutlined'
import EditOutlined from '@ant-design/icons/EditOutlined'
import LoadingOutlined from '@ant-design/icons/LoadingOutlined'

import Modal  from 'antd/lib/modal'

class Index extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            records:[],
            search:''
        }
    }
    componentDidMount()
    {
        this.LoadData();
    }
    LoadData()
    {
        this.setState({loading:true})
        API.all().then((response)=>{
            this.setState({records:response.data,loading:false});
        })
    }
    render() {
        const columns = [
            {
                title: 'First Name',
                dataIndex: 'first_name',
                key: 'project_number',
                width:150,
                sorter: (a, b) => a.first_name.localeCompare(b.first_name),
                render: (val)=><div title={val} className="text_overlap">{val}</div>
            },
            {
              title: 'Last Name',
              dataIndex: 'last_name',
              key: 'last_name',
              sorter: (a, b) => a.last_name.localeCompare(b.last_name),
              render: (val)=><div title={val} className="text_overlap">{val}</div>
            },
            {
              title: 'Phone',
              dataIndex: 'phone',
              key: 'phone',
              sorter: false,
              render: (val)=><div title={val} className="text_overlap">{val}</div>
            },
            {
              title: 'Email',
              dataIndex: 'email',
              key: 'email',
              sorter: false,
              render: (val)=><div title={val} className="text_overlap">{val}</div>
            },
            {
              title: '',
              dataIndex: 'user_id',
              key: 'user_id',
              width:80,
              sorter: false,
              render: (val)=><div><EditOutlined title="Edit" style={{color:'blue',fontSize:18,marginRight:15}} onClick={()=>{ 
                  this.setState({IsEdit:val});
              }} />
               {this.state.deleting===val &&  <LoadingOutlined spin={true} title="Delete" style={{color:'red',fontSize:18}} />}
               {this.state.deleting!==val &&<DeleteOutlined title="Delete" style={{color:'red',fontSize:18}} onClick={()=>{ 
                  Modal.confirm({title:"Do you want to delete?",onOk:()=>{ 
                        this.setState({deleting:val});
                      API.remove(val).then(()=>{
                          this.setState({deleting:false});
                          this.LoadData();
                      })
                  },okText:"Yes",cancelText:"No"})
                }} />}
              </div>
        
            }
          ];
        let records = StringUtil.search(this.state.records,this.state.search);
        return (
            <div>
                {this.state.IsAdd && <Drawer visible="true" width="1000" title="Create User" onClose={()=>this.setState({IsAdd:false})}>
                    <Form onSave={()=>{ this.setState({IsAdd:false}); this.LoadData(); }} onCancel={()=>this.setState({IsAdd:false})} />
                </Drawer>}
                {this.state.IsEdit && <Drawer visible="true" width="1000" title="Edit User" onClose={()=>this.setState({IsEdit:false})}>
                    <Form id={this.state.IsEdit} onSave={()=>{ this.setState({IsEdit:false}); this.LoadData(); }} onCancel={()=>this.setState({IsEdit:false})} />
                </Drawer>}
                <PageHeader title="Users" extra={<div className="d-flex"><Button onClick={()=>this.setState({IsAdd:true})}>Add</Button>
                <Input className="ml-2" value={this.state.search} onChange={(e)=>{ this.setState({search:e.target.value})}} allowClear placeholder="Search.." /></div>
                } />
                <Table 
                    columns={columns}
                    dataSource={records}
                    pagination={false}
                    onRow={(record, index) => {
                        return {
                            onClick: event => {   }, 
                        };
                    }}
                />    
            </div>
        );
    }
}


  export default Index;
  