import baseService from './baseService';
export function all(){
    return baseService.get('/project/all');
}

export function select(id){
    return baseService.get(`/project/select?id=${id}`);
}
export function save(data){
    if(data.project_id)
        return baseService.post('/project/update',data);
    else
        return baseService.post('/project/insert',data);
}

export function remove(id){
    return baseService.post(`/project/delete`,{project_id:id});
}

export function insert_project_users(project_id,user_id){
    return baseService.post(`/project/insert_project_users`,{project_id,user_id});
}
export function delete_project_users(project_id,user_id){
    return baseService.post(`/project/delete_project_users`,{project_id,user_id});
}