import React from 'react'
import * as users from '../../services/users'
import PageHeader from 'antd/lib/page-header'
class Index extends React.Component {
    constructor(props) {
        super(props);   
        this.state = {
            records:{},
            search:''
        }
    }
    componentDidMount()
    {
        this.setState({loading:true})
        
    }
    render() {
        
        return (
            <div>
                <PageHeader title="Dashboard" />
            </div>
        );
    }
}
export default Index;