import axios from 'axios';
//export const baseUrl = 'http://localhost/r3xgroup';
export const baseUrl = 'https://weather.r3xgroup.com/api/index.php/';


const baseService = axios.create({
    baseURL: baseUrl,
    rejectUnauthorized: false
});

baseService.addAuthToken = () => {
    baseService.defaults.headers.common['Authorization'] = `${sessionStorage.getItem('authToken')}`;
};

baseService.removeAuthToken = () => {
    sessionStorage.clear();
    baseService.defaults.headers.common['Authorization'] = '';
    window.location.href="/";
};

baseService.interceptors.response.use((config) => {
    return config;
}, (error) => {
   
    if (error.response.status === 401) {
        baseService.removeAuthToken();
    }
    return window.Promise.reject(error);
});

if (sessionStorage.getItem('authToken')) {
    baseService.addAuthToken();
}

export default baseService;