import React from 'react'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Button  from 'antd/lib/button';
import * as auth from '../../services/auth'
import {connect} from 'react-redux'
class Index extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {

        }
    }
    componentDidMount()
    {

    }
    
    Login(values)
    {
      this.setState({saving:true})
      auth.login(values).then((response)=>{
        this.setState({saving:false})
        this.props.loggedIn(response.data)
      }).catch((err)=>{
        this.setState({saving:false})
        Modal.error({title:"Login Failed!!"});
      })
    }
  
    
  
  render() {
    
    return (
      <div style={{width:'100%',height:'100vh',backgroundImage:'url("/back.jpg")',backgroundRepeat:'no-repeat',backgroundPosition:'center',backgroundSize:'cover',minWidth:1000}} className="d-flex justify-content-center align-items-center">
        <div style={{minWidth:800,width:'80%',maxWidth:1050,display:'flex',borderRadius:15,backgroundColor:'white',overflow:'hidden'}}>
          <div style={{flex:1,padding:30,height:470}}>
          <h1 className="mb-5">Sign In</h1>
          
          <Form layout="vertical" onFinish={(e)=>this.Login(e)}>
            <Form.Item name="username" label="" rules={[{required:true,message:'Please enter username'}]}>
              <Input placeholder="Username" style={{border:'none',borderBottom:'1px solid #c4c4c4',height:50}} />
            </Form.Item>
            <Form.Item name="password" label="" rules={[{required:true,message:'Please enter username'}]}>
              <Input.Password placeholder="Password" style={{border:'none',borderBottom:'1px solid #c4c4c4',height:50,marginTop:10}} />
            </Form.Item>
            <div className="d-flex mt-5">
              <Button htmlType="submit" type="primary" loading={this.state.saving} style={{backgroundColor:'black',borderWidth:0,height:40,borderRadius:6,fontSize:17,paddingLeft:20,paddingRight:20}}>Sign In</Button>
            </div>
            <div className="mt-4">Version: 1.2.8</div>  
          </Form>
          </div>
          <div style={{width:414,backgroundColor:'#0e1725',display:'flex',alignItems:'center',justifyContent:'center'}}>
           
            <div style={{color:'white', textAlign:'center',padding:15,fontSize:17}}>
              <div className="mb-5">
                <img src="logo.webp" alt="" />
              </div>
              Welcome to R3X Group, Inc., an Engineering & Environmental Consulting Firm that helps businesses like yours achive unique goals.
            </div>
          </div>
          
        </div>

      </div>
    );
  }
}



const mapStateToProps=(state)=>{
  return {
      auth:state.auth
  }
}
const mapDispatchToProps = dispatch => {
  return {
      loggedIn: (data) => {
          dispatch({ type: "USER_LOGIN", payload:data });
      }
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)

